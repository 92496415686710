

<template>
  <v-dialog v-model="toVip">
    <div class="border rounded tag-modal bg-white mx-auto p-3">
      <meal-modal
        :title="`${customer?.isVip ? 'Revoke VIP?' : 'Convert to VIP?'}`"
        :content="
          customer?.isVip
            ? 'Are you certain revoke VIP tag?'
            : 'Are you certain convert this customer to VIP?'
        "
        :rightClick="
          () =>
            customer_store.dispatch('setVip', {
              uid: customer?.uid,
              status: !Boolean(customer?.isVip),
              onSuccess: (status) => {
                toVip = false;
                customer.isVip = status;
              },
            })
        "
        :oncancel="() => (toVip = false)"
        firstBtn="No, Cancel"
        secondBtn="YES, Confirm"
        :index="cancelid"
        :closeBtn="false"
        :tickBtn="true"
      />
    </div>
  </v-dialog>
  <layout :hasTopNav="false" class="bg-[#EFEFEF] w-full">
    <div class="w-full ue-container gap-2 h-[100]">
      <top-nav :arrowback="true" :searchBar="false"> </top-nav>

      <div
        class="flex flex-wrap justify-between left-contain mb-2 items-center"
      >
        <Text :size="24" :weight="700">{{
          customer.firstName + " " + customer?.lastName
        }}</Text>

        <div class="flex items-center gap-2">
          <CustomerTag :uid="customer?.uid" :key="customer?.isVip" />
          <ue-button
            v-if="extra_permissions.includes(extra_permissions_keys.vip)"
            @click="() => (toVip = true)"
            >{{ customer?.isVip ? "Revoke VIP" : " Make VIP" }}</ue-button
          >
        </div>
      </div>
      <div class="w-full block justify-between flex flex-wrap">
        <div class="flex flex-col gap-4 left-contain rounded-[12px]">
          <div
            class="rounded-[12px] bg-white border p-4 h-[100px] flex flex-wrap justify-between items-center py-1 w-full"
          >
            <Text :weight="600">
              Amount spent <br />
              {{ formatPrice(totalAmountSpent) }}
            </Text>
            <Text :weight="600">
              Orders <br />
              {{ orders.length }}</Text
            >

            <Text :weight="600">
              Wallet balance <br />
              {{ formatPrice(customer?.wallet?.balance) }}</Text
            >
            <Text :weight="600">
              MealBot points <br />
              {{ customer?.mealPoint?.point || 0 }}
            </Text>
            <Text :weight="600">
              PD <br />
              {{ customer?.pendingTransaction?.length || 0 }}
            </Text>

            <Text :weight="600">
              Joined <br />
              <span v-if="customer.created">
                {{ formatDate(customer?.created) || 0 }}</span
              >
              <span v-else> Nov 17, 2023. 12:00 AM </span>
            </Text>
          </div>

          <div class="">
            <Text :size="16"> Wallet Actions</Text>

            <div class="my-2 flex justify-between">
              <wallet-modal
                :on-success="() => null"
                :user="customer"
                action="top-up"
                v-if="
                  extra_permissions.includes(extra_permissions_keys.top_wallet)
                "
              >
                <button class="btn btn-success">Top up</button>
              </wallet-modal>
              <wallet-modal
                :on-success="() => null"
                :user="customer"
                action="deduct"
                v-if="
                  extra_permissions.includes(
                    extra_permissions_keys.deduct_wallet
                  )
                "
              >
                <button class="btn btn-danger text-white">Deduct</button>
              </wallet-modal>
            </div>
          </div>

          <div class="rounded-[12px] bg-white p-4 w-full responsize-table">
            <table class="order-table">
              <thead class="w-100">
                <tr>
                  <!-- <th class="table-header"></th> -->
                  <th class="table-header">Order ID</th>
                  <th class="table-header">Date</th>
                  <th class="table-header">Service</th>
                  <th class="table-header">Courier</th>
                  <th class="table-header">Status</th>
                  <th class="table-header">Amount spent</th>
                  <th class="table-header">MBP</th>
                  <!-- <th class="table-header">MBP allowed</th> -->
                </tr>
              </thead>
              <tbody class="w-100">
                <tr
                  v-for="(order, index) in paginatedOrders"
                  :key="order.id"
                  class="table-row"
                  :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
                >
                  <!-- <td class="table-data">{{ index + 1 }}</td> -->
                  <td class="table-data"><OrderID :order="order" /></td>
                  <td class="table-data">{{ formatDate(order?.date) }}</td>
                  <td class="table-data">{{ order.locationType }}</td>

                  <td
                    v-if="order?.locationType?.toLowerCase() === 'delivery'"
                    class="table-data"
                  >
                    {{ order?.courier?.name || "Unassigned" }}
                  </td>
                  <td v-else class="table-data">N/A</td>

                  <td class="table-data">
                    <status
                      :items="order?.items"
                      :status="order.status"
                      :order="order"
                    />
                  </td>
                  <td class="table-data text-center">
                    {{ formatPrice(order.total_price) }}
                  </td>
                  <td class="table-data text-center h-[100%]">
                    <div class="flex gap-1 items-center">
                      {{ getMBP(order)[0]?.point }}
                      <span style="font-size: 11px" v-if="getMBP(order).length">
                        x{{ getMBP(order).length }}
                      </span>
                    </div>
                    <span
                      class="cursor-pointer"
                      v-if="
                        order?.status?.Delivered?.state && !getMBP(order).length
                      "
                      @click="() => returnMissingMealPoint(order)"
                    >
                      r
                    </span>
                  </td>
                  <!-- <td class="table-data text-center">
                    {{ getMealPointPrice(order) ? 'yes' : 'no' }}
                   
                  </td> -->
                </tr>
              </tbody>
            </table>
            <Pagination
              class="py-3"
              :currentPage="currentPage"
              :totalPages="totalPages"
              @page-change="goToPage"
            />
          </div>
          <user-wallet-history :customer="customer" />
          <wallet-timeline :customer="customer" />
        </div>
        <div
          class="rounded-[12px] border bg-white right-contain flex flex-col gap-4"
        >
          <div
            class="h-[95px] border-b flex items-center relative justify-center flex-col px-4"
          >
            <div
              v-if="
                extra_permissions.includes(extra_permissions_keys.edit_customer)
              "
              class="absolute right-3 top-3 cursor-pointer"
              @click="() => editSave()"
            >
              <span class="material-symbols-outlined">
                {{ editing ? "save" : "edit" }}
              </span>
            </div>
            <div class="w-full flex justify-between items-center">
              <input
                type="text"
                :disabled="!editing"
                :class="editing ? 'editing' : 'edited'"
                v-model="formValues.email"
              />
            </div>
            <div class="w-full flex justify-between items-center">
              <input
                type="tel"
                v-model="formValues.phoneNumber"
                :disabled="!editing"
                :class="editing ? 'editing' : 'edited'"
              />
            </div>
            <div class="flex w-[100%] pl-[5px]">
              App version: {{ customer?.appVersion || "N/A" }}
              <div class="px-3"></div>
              OS: {{ customer?.appOS || "N/A" }}
            </div>
          </div>
          <div class="p-3 flex-col gap-2 flex">
            <CustomerMealPoint :customer="customer" />
            <div class="border-t mt-4">
              <pending-transaction
                :customer="customer"
                :on-success="(new_pd) => (customer.pendingTransaction = new_pd)"
              />
            </div>
            <customer-referral :customer="customer" />
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
  
  <script>
import layout from "@/partials/Layout/layout.vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import { computed, ref, watchEffect } from "vue";
import {
  getDatabase,
  get,
  ref as dbRef,
  set,
  onValue,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import Pagination from "@/components/Pagination.vue";
import { useToast } from "vue-toast-notification";
import { saveMealPoint, store } from "@/views/store/store";
import { formatDate, formatPrice } from "@/util";
import { useRoute } from "vue-router";
import Status from "@/components/Status.vue";
import dayjs from "dayjs";
import WalletTable from "./WalletTable.vue";
import WalletModal from "./WalletModal.vue";
import WalletTimeline from "./WalletTimeline.vue";
import UeButton from "@/components/UeButton.vue";
import CustomerTag from "@/components/CustomerTag.vue";
import MealModal from "@/components/MealModal.vue";
import OrderID from "@/components/OrderID.vue";
import PendingTransaction from "./PendingTransaction.vue";
import CustomerMealPoint from "./CustomerMealPoint.vue";
import { customer_store } from "@/views/store/customers";
import { getMealPointPrice } from "@/util/getMealPointPrice";
import UserWalletHistory from "./UserWalletHistory.vue";
import CustomerReferral from './CustomerReferral.vue'
import { extra_permissions_keys } from "../Accounts/extra_permissions";

export default {
  components: {
    layout,
    TopNav,
    Text,
    Pagination,
    Status,
    WalletTable,
    UeButton,
    WalletModal,
    WalletTimeline,
    CustomerTag,
    MealModal,
    UserWalletHistory,
    OrderID,
    PendingTransaction,
    CustomerMealPoint,CustomerReferral
  },

  setup() {
    const customer = ref({});
    const editing = ref("");
    const route = useRoute().params;
    const currInput = ref("");
    const toast = useToast();
    const orders = ref([]);
    const totalAmountSpent = ref(0);
    const formValues = ref({ email: "", phoneNumber: "" });
    const wallet_action = ref("");
    const toVip = ref(false);
    const extra_permissions = ref([]);

    const getCustomers = async () => {
      const res = await get(dbRef(getDatabase(), `users/${route.uid}`));

      if (res?.val()) {
        customer.value = res.val();
        formValues.value = {
          email: customer.value.email,
          phoneNumber: customer.value.phoneNumber,
        };
      }
    };
    watchEffect(async () => {
      getCustomers();
    });

    watchEffect(() => {
      extra_permissions.value = store.state.user?.extra_permissions || [];
    });

    watchEffect(() => {
      if (customer.value.uid && customer.value?.wallet?.balance > -1) {
        onValue(
          dbRef(getDatabase(), `users/${customer.value.uid}/wallet/balance`),
          (snapshot) => {
            customer.value.wallet.balance = snapshot.val();
          }
        );
      }
    });

    watchEffect(() => {
      const getCustomersOrders = async (uid) => {
        const res = await get(
          query(
            dbRef(getDatabase(), "orders"),
            orderByChild("customer/id"),
            equalTo(uid)
          )
        );
        const yourOrders = Object.values(res.val() || {})
          .filter(
            (order) =>
              !order?.status?.Cancelled?.state &&
              !order?.status?.AwaitingPayment?.state &&
              order?.status?.Confirmed?.state
          )
          .toReversed();
        orders.value = yourOrders;
        // console.log(orders.value)
        if (yourOrders.filter((x) => x.total_price).length) {
          return {
            amount_spent: yourOrders
              .map((x) => x.total_price)
              .reduce((a, b) => a + b),
            number_of_orders: yourOrders?.length || 0,
          };
        } else {
          return { amount_spent: 0, number_of_orders: yourOrders.length || 0 };
        }
      };
      if (customer.value.uid) getCustomersOrders(customer.value.uid);
    });

    const getMBP = (order) => {
      const mbphistory = customer.value?.mealPoint?.history;
      const found = mbphistory?.filter(
        (mbp) => mbp?.orderId == order?.id && mbp?.pointType === "add"
      );
      return found || [];
    };

    const returnMissingMealPoint = async (order) => {
      toast.info("Saving mealpoint");
      try {
        await saveMealPoint({
          email: order.customer.email,
          total_price: getMealPointPrice(order),
          userId: order.customer?.id,
          pointType: "add",
          point: "",
          deliveryType: "delivery",
          orderId: order?.id,
        });
        toast.success("Successful");
      } catch (error) {
        console.log(error);
      }
    };

    // console.log(customer.value);
    const editSave = async (edit) => {
      editing.value = !editing.value;
      if (!editing.value) {
        let path = `users/${route.uid}/`;
        try {
          await set(dbRef(getDatabase(), path), {
            ...customer.value,
            ...formValues.value,
          });
          toast.success("Customer detail edit successfully");
        } catch (error) {
          toast.error("An error occured while saving details");
        }
      }
    };

    watchEffect(() => {
      if (orders.value.filter((x) => x.total_price).length) {
        totalAmountSpent.value = orders.value
          .map((x) => x.total_price)
          .reduce((a, b) => a + b);
      }
    });

    // Pagination
    const ordersPerPage = 10;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });
    return {
      customer,
      goToPage,
      paginatedOrders,
      currentPage,
      totalPages,
      editing,
      editSave,
      currInput,
      orders,
      totalAmountSpent,
      formatDate,
      formatPrice,
      formValues,
      dayjs,
      wallet_action,
      getMBP,
      toVip,
      customer_store,
      getMealPointPrice,
      returnMissingMealPoint,
      extra_permissions_keys,
      extra_permissions,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
input {
  padding: 6px;
  margin: 4px 0;
  width: 90%;
  border-radius: 4px;
}
.editing {
  border: 0.1px solid grey;
}
.edited {
  border: none;
  outline: none;
}
.left-contain {
  width: 66%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.right-contain {
  width: 33%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin: 10px 0;
  }
}

.tag-modal {
  width: 400px;
  @media screen and (max-width: 600px) {
    width: 95%;
  }
}
</style>