import dayjs from "dayjs"
import { getOrdersByTimeFrame } from './ordersByTimeFrame'
import {
    ref as uploadRef,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage"; import { projectStorage } from "@/firebase/config";


const extrasKeys = ['addons', 'sides', 'customize']

const sum = (arr = []) => {
    return arr.length ? arr.reduce((a, b) => a + b) : 0
}
const formatDate = (date) => {
    // if (onlyminutes) return dayjs(date?.replaceAll('"', '')).format('mm')
    return dayjs(date?.replaceAll('"', '')).format('MMM  DD, YYYY. hh:mm A')
}

const formatPrice = (price = 0) => "₦" + price.toLocaleString()

const getUeOrderId = (idInList, range = 4) => {
    return "UE" + Array(range - idInList?.toString()?.length).fill(0).join("") + idInList.toString()
}
const timeLeftList = [
    ...Array(13).fill(1).map((x, i) => ({ label: `${i * 5} mins`, value: i * 5 }))
]
function diff(start, end, status) {
    const startTime = (new Date(start).getTime())
    const endTime = (new Date(end).getTime())
    const res = Math.abs(endTime - startTime)
    // console.log(res)
    const hours = ` ${dayjs(res).format('H') - 1 ? dayjs(res).format('H') - 1 + ' hours' : ' '} `
    const mins = `${dayjs(res).format('m')} mins `;
    const secs = `${dayjs(res).format('s') ? dayjs(res).format('s') + ' secs' : ' '} `
    const value = `${hours}  ${mins}`
    const durations_in_mins = `${Math.floor(((res / 1000) / 60))} mins`
    // const value = ` ${res.getHours()-1 ? res.getHours() + ' hours' : ' '} ${res.getMinutes()} mins`
    return durations_in_mins
}



function diffByStatus(order, defaultValue) {
    const { status } = order
    if (status?.Pickup?.time) {
        return diff(status?.Confirmed?.time?.replaceAll('"', ''), status?.Assembled?.time)
    } else if (status?.Delivered?.time) {
        return diff(status?.Confirmed?.time?.replaceAll('"', ''), status?.Arrived?.time)
    } else {
        // return order?.countdown?.operator_timeleft !== undefined ? `${order?.countdown?.operator_timeleft} mins` : '--'
        return defaultValue
    }
}
function getPreparationTime(order, defaultValue) {
    const { status } = order
    if (status?.Prepared?.time) {
        return diff(status?.Confirmed?.time?.replaceAll('"', ''), status?.Prepared?.time)
    } else {
        // return order?.countdown?.kitchen_assembler_timeleft !== undefined ? `${order?.countdown?.kitchen_assembler_timeleft} mins` : '--'
        return defaultValue
    }
}
function getAssembledTime(order, defaultValue) {
    const { status } = order
    if (status?.Assembled?.time) {
        return diff(status?.Prepared?.time, status?.Assembled?.time)
    } else {
        // return order?.countdown?.kitchen_assembler_timeleft !== undefined ? `${order?.countdown?.kitchen_assembler_timeleft} mins` : '--'
        return defaultValue
    }
}
function getTravelTime(order, defaultValue) {
    const { status } = order
    if (status?.Dispatched?.time && status?.Delivered?.time) {
        return diff(status?.Dispatched?.time, status?.Arrived?.time)
    } else {
        // return order?.countdown?.operator_timeleft !== undefined ? `${order?.countdown?.operator_timeleft} mins` : '--'
        return defaultValue
    }
}
function getTimeDisplay(order, defaultValue, type) {
    // console.log({ defaultValue })
    switch (type) {
        case 'operator':
            return diffByStatus(order, defaultValue)
        case 'kitchen':
            return getPreparationTime(order, defaultValue)
        case 'assembler':
            return getAssembledTime(order, defaultValue)
        case 'logistics':
            return getTravelTime(order, defaultValue)
        default:
            return defaultValue
    }

}

const riderMapCircleIcon = {
    path: "M-50,100a150,150 0 1,0 300,0a150,150 0 1,0 -300,0",
    fillColor: "lightgreen",
    fillOpacity: 1,
    scale: 0.1,
    strokeColor: "inherit",
    strokeWeight: 0,
    labelOrigin: { x: 100, y: 100 },
}
const getFirebaseImageByFullPath = (path) => {
    const storageRef = uploadRef(
        projectStorage, path
    );
    const res = getDownloadURL(storageRef);
    return res
}
const getOrderID = (order) => {
    const match = order?.id?.match(/-(\d+)/);
    let transformedString;
    if (match && match[1]) {
        const extractedSubstring = match[1];
        transformedString = `UE${extractedSubstring}`;
    } else {
        transformedString = order?.id;
    }
    return transformedString
}
const getExtras = (order, index, item) => {


    let items = []

    if (order) order?.items || []
    if (parseInt(index) > -1) items = [order?.items[index]]
    if (item) items = [item]

    let result = []

    // return extrasKeys?.map(extra => {
    //     return items?.map(item => {
    //         // console.log((item[extra]))
    //         if (item[extra]) return ({ ...item[extra] })
    //     })?.flat(1)
    // })
    //     ?.flat(1)
    //     ?.filter(x => ({ ...x, key: extra }))

    extrasKeys.forEach(extrasKey => {
        items.forEach(item => {
            if (item[extrasKey]) {
                item[extrasKey]?.forEach((extra, indexInExtra) => {
                    result.push({ ...extra, extrasKey, indexInExtra })
                })
            }
        })
    });
    // console.log(result)
    return result
}
const getItemQty = ({ subItem, mainItem }) => {

    if (subItem?.qty) {
        return subItem?.qty * mainItem?.quantity
    }
    return mainItem?.quantity
}
const getItemTotalPrice = (item) => {

    const int = (str) =>
        str ? parseInt(str?.toString()?.replace(/[^0-9]/g, "")) : 0;

    const itemThatCanBePrepared = int(item.quantity) - int(item.cancelled);


    const addons =
        item?.addons?.map(
            (addon) =>
                int(addon?.amount) * (getItemQty({ subItem: addon, mainItem: item }) - int(addon?.cancelled))
        ) || [];

    const sides =
        item?.sides?.map(
            (side) =>
                int(side?.amount) * (getItemQty({ subItem: side, mainItem: item }) - int(side?.cancelled))
        ) || [];

    const customize =
        item?.customize?.map(
            (customize) =>
                int(customize?.amount) *
                (getItemQty({ subItem: customize, mainItem: item }) - int(customize?.cancelled))
        ) || [];

    const totalOntheSide = sum(addons) + sum(sides) + sum(customize);
    const mainItemPrice =
        int(item.amount) * itemThatCanBePrepared ||
        int(item?.realAmount) * itemThatCanBePrepared ||
        0;

    // console.log(item?.addons, item?.sides, item?.customize, totalOntheSide, mainItemPrice)
    return mainItemPrice + totalOntheSide;
};

const int = (str) =>
    str ? parseInt(str?.toString()?.replace(/[^0-9]/g, "")) : 0;

const getItemTotalPriceNew = (item, useCancelled) => {

    // console.log("i55tem88,)-=h7".replace(/[^0-9]/g, ""));

    const itemThatCanBePrepared = int(item.quantity) - int(item.canceled);

    const extras = getExtras(null, null, item)
    const totalOntheSide = sum(extras.map((extra) => int(extra?.amount) * (item.quantity - int(extra?.cancelled)))) || 0

    // const addons =
    //     item?.addons?.map(
    //         (addon) =>
    //             int(addon?.amount) * (item.quantity - int(addon?.cancelled))
    //     ) || [];

    // const sides =
    //     item?.sides?.map(
    //         (side) =>
    //             int(side?.amount) * (item.quantity - int(side?.cancelled))
    //     ) || [];

    // const customize =
    //     item?.customize?.map(
    //         (customize) =>
    //             int(customize?.amount) *
    //             (item.quantity - int(customize?.cancelled))
    //     ) || [];

    // const totalOntheSide = sum(addons) + sum(sides) + sum(customize);
    // console.log(item?.addons,item?.sides,item?.customize)
    const mainItemPrice =
        int(item.amount) * itemThatCanBePrepared ||
        int(item?.realAmount) * itemThatCanBePrepared ||
        0;

    return mainItemPrice + totalOntheSide;
};

const checkTotal = (order, log) => {
    const total_price = order?.total_price
    const items = order?.items || []
    const delivery_fee = order?.delivery || 0
    const service_fee = order?.service || 0
    const totals = items.map(getItemTotalPrice);

    const hasMBP = order.items.find(item => item?.point)
    const promoUsed = order?.promoUsed
    const referralUsed = order?.referralUsed === total_price ? 0 : order?.referralUsed
    const total = sum(totals)
    const calculated_total = (total + delivery_fee + service_fee - (referralUsed || 0))
    let state = total_price === calculated_total
    let isNormal = true
    let type = 'NORMAL'
    if (hasMBP) type = 'MBP', state = true, isNormal = false
    if (order?.promoUsed) type = 'PROMO', state = true, isNormal = false
    const details = ({
        order: order?.id,
        app_price: total_price,
        calculated_total,
        difference: Math.abs(total_price - calculated_total),
        delivery_fee: delivery_fee || 'Missing',
        service_fee: service_fee || "Missing",
        // hasMBP: hasMBP ? `${hasMBP} points` : 'no',
        // promoUsed: promoUsed ? `yes` : 'no',
        // referralUsed: referralUsed ? `${referralUsed}` : 'no',
    })
    // if (referralUsed) type = 'REFERRAL', state = true, isNormal = false
    // console.log({ state, type, hasMBP, promoUsed }, order?.id)

    return {
        state, type, hasMBP, promoUsed, isNormal, referralUsed, details
    }
}
function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

const checkRepeatedTimeline = (order) => {

    let messages = []
    const timeline = Object.values(order?.timeline || {})
    timeline.forEach(item => {
        if (!item?.message?.includes("prepar") && !item?.message?.includes("assemb") && !item?.message?.includes("confirmed")) {
            messages.push(item?.message)
        }
    })
    const messagesToCheck = ['started the trip', 'arrived at the customer', 'delivered the order']
    // const lines = timeline.filter(item => messagesToCheck.filter() item?.message?.includes('started the trip'))
    // console.log(lines)
    return hasDuplicates(messages)
}

const getTransactionType = (type) => {
    switch (type) {
        case "wallet-top-up":
            return "credit";
        case "wallet-reversal":
            return "credit";
        case "wallet-withdrawal":
            return "debit";
        case "order-purchase":
            return "debit";
        case "wallet-deduction":
            return "debit";
        default:
            return "credit";
    }
};
const getImg = (fullPath) => {
    let url = `https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/ingredients%2F${fullPath?.replace(
        'ingredients/',
        ''
    )}?alt=media&token=5c8d8a96-6b88-4d7e-903f-017ea27e84df&_gl=1*1ev9nbw*_ga*OTQxODg2OTQyLjE2OTY2MDA5OTM.*_ga_CW55HF8NVT*MTY5Njg3OTI2Ni4yNzUuMS4xNjk2ODc5MjgwLjQ2LjAuMA..`
    let name = fullPath?.replace('ingredients/', '')
    return name ? url : undefined
}
const getImgFromFirebase = (folder, fullPath) => {
    let url = `https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/${folder}%2F${fullPath}?alt=media&token=c723e8de-4d6e-45aa-8d21-d289c06e2cbd&_gl=1*13fw5p9*_ga*OTQxODg2OTQyLjE2OTY2MDA5OTM.*_ga_CW55HF8NVT*MTY5ODc2ODYxOS4zNDQuMS4xNjk4NzY4NjM3LjQyLjAuMA..`

    return url
}
function convertTo24Hour(time) {
    var hours = parseInt(time.substr(0, 2));
    if (time.indexOf("AM") != -1 && hours == 12) {
        time = time.replace("12", "0");
    }
    if (time.indexOf("PM") != -1 && hours < 12) {
        time = time.replace(hours, hours + 12);
    }
    return time.replace(/(AM|PM)/, "");
}

const extractScheduledOrderDate = (scheduleDateTime) => {
    // console.log(scheduleDateTime)
    const time = String(scheduleDateTime?.time || " - ")
        .split("-")[0]
        ?.replaceAll(" ", "");
    const date = dayjs(scheduleDateTime?.date?.replaceAll('"', ""));
    // console.log(time,date)
    const hours = convertTo24Hour(time).split(":")[0];

    return new Date(date).setHours(hours, 0, 0, 0);
};
const scheduledTimeLeft = (order) => {
    // console.log(first);
    const scheduled_time = dayjs(extractScheduledOrderDate(order?.scheduleDateTime));
    const units = ["weeks", "days", "hours", "minutes", "seconds"];
    let res = "";

    for (let index = 0; index < units.length; index++) {
        let unit = units[index];
        const differ = Math.abs(dayjs().diff(scheduled_time, unit));
        if (differ) {
            // if (differ == 1) delete unit[unit.length-1];
            res = `${differ} ${unit}`;
            break;
        }
    }

    return res;
};


function getDates(startDate, stopDate, unit, format) {
    let dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = dayjs(currentDate).add(1, unit);
    }
    return dateArray?.map((date) => dayjs(date).format(format));
}



const writeOrderTotalInTimeline = (total) => `<b><i style="color:#f60100" >${formatPrice(total)} </i></b>`
export {
    sum, formatDate, formatPrice, getUeOrderId, timeLeftList, diff, diffByStatus,
    getAssembledTime, getPreparationTime, getTravelTime, getOrdersByTimeFrame,
    riderMapCircleIcon, getFirebaseImageByFullPath, getImg, getImgFromFirebase,
    getTimeDisplay, getOrderID, getItemTotalPrice, checkTotal, getTransactionType,
    checkRepeatedTimeline, convertTo24Hour, extractScheduledOrderDate, scheduledTimeLeft,
    extrasKeys, getExtras, int, getDates, writeOrderTotalInTimeline, getItemQty
}