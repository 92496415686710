<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="ue-container bg-light w-full h-full" v-if="!selectId">
      <TopNav :onSearch="search" />
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> {{ message }}</Text>
              <span class="capitalize">{{ selectId }}</span>
            </h1>
            <!-- <div>
              <button
                class="button"
                :class="{ active: status === 'active' }"
                @click="select('active')"
              >
                Active Orders
              </button>
              <button
                class="button"
                :class="{ active: status === 'completed' }"
                @click="select('completed')"
              >
                Completed Orders
              </button>
            </div> -->
            <status-button v-if="status_list?.length"
              :current="status"
              :buttons="status_list"
              :on-change="
                (data) => {
                  status = data.key;
                  message = data.message;
                }
              "
            />
          </div>
          <div class="flex mt-5 items-center gap-[0px] justify-end">
            <input-field
              :id="'sle'"
              :label="' '"
              :type="'select'"
              @input="selectKitchen"
              class="outline-none p-3"
              :placeholder="'All kitchens'"
              :options="kitchen.map((x) => ({ label: x.id, value: x.id }))"
            ></input-field>
            <time-left-dropdown
              :getTime="(time) => (selectedTime = time)"
              v-if="status === 'active'"
            />
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div v-if="status == 'active' || status == 'completed'" class="responsize-table">
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Date</th>
                <th class="table-header">Customer Name</th>
                <th class="table-header">Service</th>
                <th class="table-header">Courier</th>
                <th class="table-header">Status</th>
                <th class="table-header">
                  {{ status === "active" ? "Time Left" : "Time Spent" }}
                </th>
                <th class="table-header">Tag</th>
                <th class="table-header">Action</th>
                <th class="table-header">Print</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in paginatedOrders"
                :key="order.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data flex gap-2">
                  {{ index + 1 + 15 * (currentPage - 1) }}

                  <div class="w-[20px]">
                    <CheckCircleIcon class="text-success" v-if="checkTotal(order)?.state && checkTotal(order)?.isNormal" />
                    <CheckCircleIcon
                      style="font-size: 20px !important"
                      v-if="checkTotal(order)?.hasMBP"
                      class="text-danger"
                    />
                    <CheckCircleIcon
                      style="font-size: 20px !important"
                      class="text-[orange]"
                      v-if="checkTotal(order)?.promoUsed"
                    />
                  </div>

                  <bug-checker :order="order" />
                </td>

                <td class="table-data">
                  <OrderID :order="order" />
                </td>
                <td class="table-data">
                  {{
                    dayjs(order.date?.replaceAll('"', "")).format(
                      "MMM DD. hh:mmA "
                    )
                  }}
                </td>
                <td class="table-data">
                  <!-- {{
                    `${order?.customer?.firstName} ${order?.customer?.lastName}`
                  }} -->
                  <customer-name :customer="order?.customer" />
                </td>
                <td class="table-data">{{ order.locationType }}</td>
                <td
                  v-if="order.locationType?.toLowerCase() === 'delivery'"
                  class="table-data"
                >
                  {{ order.courier.name ? order.courier.name : "Unassigned" }}
                </td>
                <td v-else class="table-data">N/A</td>
                <td class="table-data flex items-center">
                  <Status
                    :items="order.items"
                    :status="order.status"
                    :order="order"
                    :optimize="status == 'completed'"
                  />
                  <span v-if="order?.mealPointSaved">
                    <span
                      style="font-size: 8px"
                      v-for="saved in Object.keys(order?.mealPointSaved)"
                      :key="saved"
                      >✓</span
                    >
                    <!-- {{ .map(yes => '') }}  -->
                  </span>
                </td>

                <td class="table-data">
                  <!-- {{ diffByStatus(order) }} -->
                  <time-left
                    :order="order"
                    :count-down="order?.locationType === 'Delivery' ? 45 : 35"
                    type="operator"
                  />
                </td>
                <td class="table-data">
                   <customer-tag :uid="order?.customer?.id" :order="order" />
                </td>
                <!-- <td class="table-data"><Refund :order="order" :refundAmount="order.total_price"  />{{ order?.totalAmount }}</td> -->

                <td class="table-data">
                  <router-link
                    :to="`/operator/orders/${order?.id}`"
                    class="action-link !underline"
                    >View</router-link
                  >
                </td>
                <td class="table-data cursor-pointer">
                  <receipt :order="order">
                    <span class="underline">{{
                      order?.printed ? "Printed" : "Print"
                    }}</span>
                  </receipt>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
        <div>
          <unconfirmed-orders
            v-if="status == 'unconfirmed'"
            :on-confirm-order="
              () => {
                status = 'active';
                message = 'Active Orders';
              }
            "
          />
          <cancelled-orders v-if="status == 'cancelled'" />
          <scheduled-orders
            v-if="status == 'scheduled'"
            :on-clear-scheduled-orders="
              () => {
                status = 'active';
                message = 'Active Orders';
              }
            "
          />
        </div>
      </section>
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" /> -->
    <div v-else>loading...</div>
  </layout>
</template>
        
      <script>
import { ref, computed, watchEffect } from "vue";
import Pagination from "@/components/Pagination.vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Modal from "@/components/Modal.vue";
import { getData } from "@/firebase/config";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/InputField.vue";
import Text from "@/components/Text.vue";
import StatusButton from "@/components/StatusButton.vue";
import ScheduledOrders from "@/partials/ScheduledOrders.vue";
import {
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedOutline,
} from "@mdi/js";

import CustomerName from "@/components/CustomerName.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  diffByStatus,
  getOrderID,
  checkTotal,
  checkRepeatedTimeline,
} from "@/util";
import Status from "@/components/Status.vue";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import TimeLeftDropdown from "@/components/TimeLeftDropdown.vue";
import Receipt from "@/components/Receipt.vue";
import Refund from "../Cancelled/Refund.vue";
import UnconfirmedOrders from "@/partials/UnconfirmedOrders.vue";
import CancelledOrders from "@/partials/CancelledOrders.vue";
import { searchOrder } from "@/util/filterOrder";
import TimeLeft from "@/components/TimeLeft.vue";
import CustomerTag from "@/components/CustomerTag.vue";
import dayjs from "dayjs";
import OrderID from "@/components/OrderID.vue";
import BugChecker from "@/components/BugChecker.vue";

import { BeakerIcon, CheckCircleIcon } from "@heroicons/vue/24/solid";
import CheckBox from "@/components/CheckBox.vue";

export default {
  components: {
    TopNav,
    Pagination,
    // OrderModal,
    Modal,
    InputField,
    Text,
    Status,
    Layout,
    TimeLeftDropdown,
    Receipt,
    Refund,
    StatusButton,
    UnconfirmedOrders,
    CancelledOrders,
    TimeLeft,
    CustomerTag,
    OrderID,
    CustomerName,
    CheckCircleIcon,
    CheckBox,
    BugChecker,
    ScheduledOrders,
  },
  setup() {
    const orders = ref(store.state.orders);
    const status = ref("active");
    const message = ref("Active Orders");
    const kitchen = getData("restaurants");
    const selectedOrder = ref({});
    const route = useRoute();
    const router = useRouter();
    const selectId = ref(route.params.id);
    const selectedKitchen = ref("");
    const searchValue = ref("");
    const selectedTime = ref("");
    const tab = ref("");

    const checkboxModel = ref(true);
    const selectKitchen = (e) => {
      selectedKitchen.value = e?.target?.value;
    };
    const status_list = ref([]);

    watchEffect(() => {
      status_list.value = [
        {
          message: `Active Orders`,
          key: "active",
          tab_bracket: `${
            store.state.order_tabs?.operator_active?.length || 0
          }`,
        },
        {
          message: `Unconfirmed Orders `,
          key: "unconfirmed",
          tab_bracket: `${store.state.unconfirmedOrders?.length}`,
        },
        {
          message: "Cancelled Orders",
          key: "cancelled",
          tab_bracket: "",
        },
        {
          message: "Completed Orders",
          key: "completed",
          tab_bracket: "",
        },
        {
          message: "Scheduled Orders",
          key: "scheduled",
          tab_bracket: `${store.state.scheduledOrders?.length}`
        }
      ];
    });

    const search = (e) => {
      searchValue.value = e;
    };

    watchEffect(() => {
      selectId.value = route.params.id;
      if (selectId.value) {
        selectedOrder.value = store.state.orders.find(
          (x) => x.id === selectId.value
        );
      }
    });

    const select = (type) => {
      status.value = type;
      message.value = type === "active" ? "Active Orders" : "Completed Orders";
    };

    watchEffect(async () => {
      // console.log(route.path);
      let statusFilter = store.state.orders.filter((x) => {
        if (x.status?.Delivered?.state) {
          return status.value === "active"
            ? !x?.status?.Delivered?.state
            : x.status?.Delivered?.state || x?.status?.Pickup?.state;
        } else {
          return status.value === "active"
            ? !x?.status?.Pickup?.state
            : x.status?.Delivered?.state || x?.status?.Pickup?.state;
        }
      });

      if (searchValue.value) {
        // orders.value = store.state.orders.filter(
        //   (x) =>
        //     x.id?.toLowerCase().includes(searchValue.value.toLowerCase()) ||
        //     `${x.customer?.firstName} ${x.customer?.lastName}`
        //       ?.toLowerCase()
        //       ?.includes(searchValue.value.toLowerCase())
        // );

        const res = await searchOrder(store.state.orders, searchValue.value);
        orders.value = res;
      } else {
        orders.value = statusFilter;
      }

      if (selectedTime.value === "htl") {
        orders.value.sort((a, b) => {
          return b.countdown.operator_timeleft - a.countdown.operator_timeleft;
        });
      } else if (selectedTime.value === "lth") {
        orders.value.sort((a, b) => {
          return a.countdown.operator_timeleft - b.countdown.operator_timeleft;
        });
      }
    });

    // Pagination
    const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });
    watchEffect(() => {
      searchValue.value;
      status.value;
      currentPage.value = 1;
    });

    return {
      ordersPerPage,
      currentPage,
      totalPages,
      goToPage,
      paginatedOrders,
      select,
      status,
      message,
      sum,
      selectedOrder,
      kitchen,
      selectId,
      selectKitchen,
      router,
      search,
      formatDate,
      getUeOrderId,
      diffByStatus,
      selectedTime,
      getOrderID,
      dayjs,
      checkTotal,
      checkRepeatedTimeline,
      checkboxModel,
      mdiCheckboxBlankOutline,
      mdiCheckboxMarked,
      mdiCheckboxMarkedOutline,
      status_list,
    };
  },
};
</script>
        
        <style scoped>
.button {
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px #f60100 solid;
  background-color: #fff;
  color: #f60100;
  margin-left: 20px;
}
.button.active {
  background: linear-gradient(180deg, #ff9103 0%, #f60100 100%);
  color: white;
  border: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #ccc; */
}

.table-row {
  height: 60px;
}

.table-row.even-row {
  background-color: #f2f2f2;
}

.table-row.odd-row {
  background-color: #fff;
}

.status-button {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(255, 145, 3, 0.24) 0%,
    rgba(246, 1, 0, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.status-button2 {
  padding: 5px 10px;
  background: linear-gradient(
    202deg,
    rgba(74, 224, 117, 0.428) 0%,
    rgba(0, 246, 78, 0.24) 100%
  );

  border: none;
  color: #333;
  font-weight: bold;
}

.action-link {
  text-decoration: none;
  color: #f60100;
}
</style>